import React, { useState, useEffect}  from "react";
import {db, storage} from './Firebase';
import { collection, doc, addDoc, serverTimestamp, setDoc} from "firebase/firestore";
import { ref , uploadBytes , getDownloadURL} from "firebase/storage"
import { v4 } from 'uuid'
import { CabinSharp } from "@mui/icons-material";

const SavePost = async (postType, title, body , pickedDate, images, userID ) => { 
    const type = postType;
    const dateSelected = pickedDate;
    const image = images;
    const urlList = [];
    const imagesNameArr = [];
    const uid = userID;

    let imageName;
    let newName;
    function storeImages(){
        // console.log("STORING IMAGES");
        return new Promise((resolve) => {
            const resolveImages = image.map((item) => {
                const thisFile = item.file;
                // console.log(item.file)
                // const newName = `images/events/${v4()}.jpg`;
                if (type === "newsPosts"){
                    newName = `images/news/${v4()}.jpg`;
                }
                else {
                    newName = `images/events/${v4()}.jpg`;
                }
                const storageRef = ref(storage,newName);
                imageName = storageRef.name;
                imagesNameArr.push(imageName);
                uploadBytes(storageRef, thisFile).then((snapshot) =>{

                    getDownloadURL(ref(storage, newName)).then((url) =>{
                        const thisURL = url;
                        urlList.push(thisURL);
                    });
                });
            });
            resolve(resolveImages);
        })
    }
    
    async function handleImages(){
        await storeImages();
    }

    // console.log("type:", type)
    console.log(image);

    if(type == "newsPosts"){
        handleImages();
        // console.log("post type:", type)
        try{
            // const newsRef = doc(collection(db, "newsPosts" ), {
            //     header: title,
            //     dateCreated: serverTimestamp(),
            //     input: body,
            //     imageUrl: urlList,
            //     userID: uid, 
            // });
            const newsRef = doc(collection(db, "newsPosts"));
            setTimeout(function(){
            const docData = ({
                header: title,
                dateCreated: serverTimestamp(),
                input: body,
                imageUrl: urlList,
                userID: uid,
            })
                console.log(docData)
                setDoc(newsRef, docData);
                console.log("Document ID:", newsRef.id)
            }, 3000);
            console.log("Document ID:", newsRef.id)
        } catch(err) {
            console.log("error:", err)
        }
    } else {
        // console.log("post type:", type);
        handleImages();
        const eventRef = doc(collection(db, "events"));
        setTimeout(function(){
            const docData = ({
                header: title,
                dateCreated: serverTimestamp(),
                input: body,
                imageUrl: urlList,
                dateSelected: dateSelected,
                userID: uid,
            })
            setDoc(eventRef, docData);
            console.log("Document ID:", eventRef.id)
        }, 3000);
    }
};

export default SavePost;
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyBXHmFeEuC-BjP4W7T-HRR4IiaSYmme-KE",
    authDomain: "stage1-21c9c.firebaseapp.com",
    projectId: "stage1-21c9c",
    storageBucket: "stage1-21c9c.appspot.com",
    messagingSenderId: "97047757996",
    appId: "1:97047757996:web:29f1b27c52015fd7abcb09",
    measurementId: "G-BVC1V75T3Q"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();
const currUser = auth.currentUser;

const logIn = async (email,password) => {
  try{
    await signInWithEmailAndPassword(auth, email, password)
    console.log("user is signed in")
  }catch(err){
    console.error(err);
  }
}

const logOut = () => {
  signOut(auth);
}

export{
  auth, db, currUser, storage, logIn, logOut
}
import React from "react";
import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/system/Box";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Divider from "@mui/material/Divider";
import Image from "mui-image";
import image1 from "../Images/edison.jpg"
import image2 from "../Images/inventors.jpg";
import image3 from "../Images/chemistry.jpg";

const activitiesText = [
  {
    name: "Invention Education",
    text: `Rolling Robots Outreach is providing a workshop for students to pursue creative projects in invention.  
 The studio is equipped with state of the art digital manufacturing equipment, electronics test equipment and hand tools.  It is a creative space encouraging development of new projects and ideas.
 `,
    image: "",
  },
  {
    name: "Young Inventors",
    text: `This program is an electronics product design project.  It is designed for students with great curiosity and focus but no experience required.  We will use primarily the Arduino platform to learn and produce digital and analog electronics projects.  Students will learn not only the circuitry but also packaging and product design.  One project goal for this group is to design and produce our own battery tester or voltmeter.
`,
    image: image2,
  },
  {
    name: "Edison Hackers",
    text: `Thomas Edison once said "in order to invent you need a good imagination and a pile of junk". At Rolling Robots we have accumulated a pile of high tech junk including, older 3D printers, robot systems , computers and displays.  The Edison hackers will be reverse engineering and creating projects using this pile of junk. The students will be doing product tear down exercises to determine how things work and how they are made.  After these activities the students create projects of their own using what the have learned or extracted from this pile of junk. The goal project might be a combination lock picker, parts sorting machine, 2D computer art machine etc.`,
    image: image1,
  },
  {
    name: "JV InvenTeam",
    text: `A workshop for teaching invention using the JV InvenTeam curriculum developed by the Lemelson-MIT foundation. This provides an opportunity for exceptional STEM students to move beyond the classroom and beyond the robotics team to develop the skills to solve real world problems using their creative minds. Through a series of modules students learn the science , engineering and techniques of invention. Just to name a few we have modules on Heat and Heat transfer,  Modeling and Molding,  Electronic Textiles, Green Chemistry and Hydroponic Gardening.
`,
    image: image3,
  },
  {
    name: "InvenTeam",
    text: `Our top High school students are invited to participate on a self directed InvenTeam.  The students propose and develop an invention idea for submission to the Lemelson-MIT InvenTeam competition as well as other programs.  In the past this group has won a grant from Lemelson-MIT for a Sleep improvement Device for Teens which has had a US Patent application filed and published. The team was also selected as a top 25 world Finalist in the X-Prize Next Gen Mask Challenge for their mask design including temperature control and voice amplification. This group has recently submitted a proposal to the Lemelson-MIT foundation for an advanced walking assistance device for seniors.`,
    image: "",
  },
];

const Activities = () => {
  return (
    <Container sx={{backgroundColor:"#1b5072"}}>
      <Grid2 container spacing={3} >
        <Grid2 item xs={12}> 
          <Typography variant="h2" color="white" style={{fontFamily:"Bungee",textAlign:"center", margin: "20px 0px -20px 0px"}}><AutoAwesomeIcon />Activities<AutoAwesomeIcon /></Typography>
        </Grid2>
        <Grid2 item xs={12} style={{ padding: "0px 30px", margin: "auto" }}>
          {activitiesText.map((text) => (
            <Paper sx={{ padding: "20px", margin: "30px" }}>
              <Grid2 item sx={12}>
                <Paper sx={{ background:"linear-gradient(to right, #ffc72c, #ffffff)"}}>
                  <Typography variant="h3" sx={{ marginLeft: "10px",color:"#373737" }}>
                    {text.name}
                  </Typography>
                </Paper>
              </Grid2>
              <Divider/>
              <Grid2 item xs={10} marginLeft="20px">
                <Typography>{text.text}</Typography>
              </Grid2>
              {text.image != "" ? <Image src={text.image} /> : null}
            </Paper>
          ))}
          <Divider />
        </Grid2>
        <Box></Box>
      </Grid2>
    </Container>
  );
};

export default Activities;
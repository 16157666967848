import React from "react";
import { CssBaseline } from "@mui/material";
import Homepage from "./Pages/Homepage";




// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
   
      <div>
        <div>
          <CssBaseline />
          <Homepage />
        </div>
      </div>

  );
};

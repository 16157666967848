import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { AppBar, IconButton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import logo from "../Images/Logonospace.svg";

const drawerWidth = 240;

const textStyle = {
  one: {
    textDecoration: "none",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
  },
  two: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
    padding: "10px",
  },
};

const navItems = [
  {
    name: "Homepage",
    path: "/",
  },
  {
    name: "Events",
    path: "/events",
  },
  {
    name: "Activities",
    path: "/activities",
  },
  {
    name: "News",
    path: "/news",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "post",
    path: "/newpost",
  },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const locationObj = useLocation()
  console.log(locationObj)

  const currentPage = (path) => {
    if (path == locationObj.pathname) {
      return true
    } else {
      return false
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navBarList = (
    <Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <Link to={item.path} style={textStyle.two}>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" position="sticky" role="navigation" aria-label="Site Menu">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/"><img src={logo} alt="logo" height="50px" /></Link>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Link to={item.path} style={{...textStyle.one, }}>
                <Button key={item.name} sx={{ color: currentPage(item.path) ? '#000' : '#fff', }}>
                  <Typography style={{ fontFamily: "Montserrat", fontWeight: currentPage(item.path) ? 700 : 500 }}>{item.name}</Typography>
                </Button>
              </Link>
            ))}
            <Link to="/donations" style={{textDecoration:"none"}} >
              <Button
                sx={{backgroundColor: currentPage('/donations') ? null : "secondary.main", color:"black"}}
              >
                 <Typography style={{ fontFamily: "Montserrat", fontWeight: currentPage('/donations') ? 700 : 500 }}>Donate</Typography>
            </Button>
            </Link>
            
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" role="navigation">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {navBarList}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;

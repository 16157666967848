import React, { useState, useEffect } from "react";
import CardComponent from "../components/Card";
import { db, auth } from "./Firebase";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth"

const NewsPosts = (props) => {
  // console.log("NewsPosts.js");
  let type ="newsPosts";
  const [allNews, setAllNews] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  //----------------------------------------------------------------
  const AuthStateChange = async () => {
    onAuthStateChanged(auth, (user) => {
      if(user){
        setIsEditable(true)
      } else {
        console.log("NO USER SIGNED IN")
      }
    });    
  }
  
  useEffect(() => {
    AuthStateChange();
  })
  //----------------------------------------------------------------

  const getAllNewsPosts = async () => {
    try {
      const newsArr = [];
      let imageTemp;

      const newsRef = collection(db, "newsPosts");
      const q = query(newsRef, orderBy("dateCreated", "desc"));
      const newsSnapshot = await getDocs(q);

      newsSnapshot.forEach((doc) => {
        const newsDate = doc.data().dateCreated.toDate().toLocaleDateString();

        if(doc.data().imageUrl !== undefined){
          if(typeof doc.data().imageUrl === 'string'){
            imageTemp = doc.data().imageUrl;
          }else if(doc.data().imageUrl == null){
            imageTemp = null;
          }else{
            imageTemp = doc.data().imageUrl[0];
          }
        } else {
          imageTemp = null;
        }

        const news = {
          id: doc.id,
          header: doc.data().header,
          date: newsDate,
          body: doc.data().input,
          image: imageTemp,
        };
        newsArr.push(news);
      });
      setAllNews(newsArr);
    } catch (err) {
      console.log(err);
    }
  };
  //----------------------------------------------------------------

  //================================================================
  useEffect(() => {
    getAllNewsPosts();
  }, []);

  //----------------------------------------------------------------
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  const sliced = allNews.slice(0, 2);
  //----------------------------------------------------------------

  return (
    <div>
      {!props.homepage ? (
        <Container sx={{ backgroundColor: "#1b5072", marginTop: "30px" }}>
          {allNews.map((item, index) => {
            return (
              <Grid2 item xs={12}>
                <Box style={{ padding: "10px", margin: "10" }}>
                  <CardComponent
                    id={item.id}
                    newsheader={item.header}
                    userinput={item.body}
                    timestamp={item.date}
                    img={item.image}
                    color="#ffe6a2"
                    homepage={false}
                    clickable={false}
                    editable={isEditable}
                    docID={item.id}
                    type={type}
                  />
                </Box>
              </Grid2>
            );
          })}
        </Container>
      ) : (
        <Grid2
          item
          xs={12}
          style={{
            display: "flex",
            flexFlow: "row wrap",
            gap: "30px",
            justifyContent: "center",
          }}
        >
          {sliced.map((item) => (
            <CardComponent
              id={item.id}
              newsheader={item.header}
              timestamp={item.date}
              userinput={item.body}
              homepage={true}
              clickable={true}
              cardwidth="600px"
              img={item.image}
            />
          ))}
        </Grid2>
      )}{" "}
      {/* if the page this compoenent is on is the home page than it will only display 2 + it will be clickable*/}
    </div>
  );
};

export default NewsPosts;

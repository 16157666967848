import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Facebook, YoutubeIcon, Twitter } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";

const Footer = () => {
  const textStyle = {
    textDecoration: "none",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
  };
  return (
    <Box sx={{ bgcolor: "primary.main" }} role="contentinfo">
      
      <Box
        sx={{
          position: "relative",

          marginTop: "10px",
          bottom: 0,
          textAlign: "center",
          bgcolor: "primary.main",
          width: "100%",
          zIndex: "1"
        }}
      >
        <Box height="10px" sx={{backgroundColor:"secondary.main",width:"100%"}}> </Box>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Box sx={{ padding: "10px" }}>
            <IconButton
              href="https://twitter.com/rollingrobots"
              aria-label="twitter link button"
              sx={{ "& :hover": { color: "secondary.main" } }}
            >
              <TwitterIcon />
            </IconButton>

            <IconButton
              aria-label="youtube link button"
              href="https://www.youtube.com/user/rollingrobots"
              sx={{ "& :hover": { color: "secondary.main" } }}
            >
              <YouTubeIcon href="" />
            </IconButton>

            <IconButton
              aria-label="instagram link button"
              href="https://www.instagram.com/rollingrobots/"
              sx={{ "& :hover": { color: "secondary.main" } }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              aria-label="facebook link button"
              href="https://www.facebook.com/rollingrobots"
              sx={{ "& :hover": { color: "secondary.main" } }}
            >
              <Facebook />
            </IconButton>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <a href="" className="footer_ui" style={textStyle}>
            <Typography>Contact Us</Typography>
          </a>
          <Link to="/locations" className="footer_ui" style={textStyle}>
            <Typography>Locations</Typography>
          </Link>
          <Link className="footer_ui" style={textStyle}>
            <Typography>Legal</Typography>
          </Link>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Box sx={{ padding: "10px" }}>
            <div className="footer_rows">© 2022 Rolling Robots Inc.</div>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;

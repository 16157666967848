import React from 'react'
import { Button, Typography, useMediaQuery } from '@mui/material'
import { Container, Box, Paper } from '@mui/system'
import Image from 'mui-image'
import image1 from '../Images/Donation1.jpg'
import image2 from '../Images/Donation2.jpg'
import image3 from '../Images/Donation3.jpg'

const Donations = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <Container role='main'>
      <Box
        sx={{
          minHeight: '80vh',
          backgroundColor: 'white',
          padding: '20px 40px',
          display: 'grid',
          gridTemplateColumns: { xs: '100%', sm: '50% auto' },
          gridGap: '40px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant='h2'
            sx={{ marginBottom: '10px', fontSize: '3.5rem' }}
          >
            Support Education {mobile ? null : <br />} in STEM
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>
            Support the efforts of Rolling Robots Outreach to provide excellent
            opportunities for students in STEM. Many of our team activities
            would not be possible without the generous support of our donors.
          </Typography>
          <Box align='center'>
            <form
              action='https://www.paypal.com/donate'
              method='post'
              target='_top'
            >
              <input
                type='hidden'
                name='hosted_button_id'
                value='6V753MCJSRMBJ'
              />
              {/* <input
                type='image'
                src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
                border='0'
                name='submit'
                title='PayPal - The safer, easier way to pay online!'
                alt='Donate with PayPal button'
              /> */}
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#ffc72c',
                  fontSize: '20px',
                  fontWeight: 800,
                  padding: '10px 25px',
                  margin: '20px 0px',
                  color: '#000',
                  '&:hover': { backgroundColor: '#f0b100' }
                }}
                type='submit'
                name='submit'
                title='PayPal - The safer, easier way to pay online!'
              >
                Donate
              </Button>
              <img
                alt=''
                border='0'
                src='https://www.paypal.com/en_US/i/scr/pixel.gif'
                width='1'
                height='1'
              />
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gridTemplateRows: 'auto auto',
            gridColumn: {xs: '1/2', sm: '2/3'},
            gridRow: {xs: '3/4', sm: '1/2'}
          }}
        >
          {mobile ?<Typography variant='h5' sx={{gridColumn: '1/5', marginBottom: '10px'}}>Previous Fundraisers</Typography> : <></>}
          <Box
            sx={{
              gridColumn: { xs: '1/5', md: '2/5' },
              alignSelf: 'end',
              marginBottom: '10px'
            }}
          >
            <Image
              src={image1}
              height='250px'
              width='100%'
              alt='image of a rolling robots team in front of a space shuttle '
            />
            <Typography sx={{ fontWeight: 400, margin: '5px 0px' }}>
              Washington DC Air and Space Museum for the Robotics Tournament
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: { xs: '1/5', md: '1/4' },
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Image
              height='250px'
              width='100%'
              src={image2}
              alt='image of a Rolling Robots team at a competition posing in front of their booth'
            />
            <Typography sx={{ fontWeight: 400, margin: '5px 0px' }}>
              Cambridge, Massachusetts to present at MIT EurekaFest.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: '100px', gridColumn: {xs:'1/2', sm: '1/3'}}}>
        <Typography variant='h2' sx={{marginBottom: '20px', fontSize: '3.5rem'}}>Current Fundraiser</Typography>
        <Box sx={{display: 'grid', gridTemplateColumns: {xs: '100%', sm: '60% auto'}, gridGap: '20px '}}>
          <Image
            // height='250px'
            width='100%'
            src={image3}
            alt='image of a Rolling Robots team at a competition posing in front of their booth'
          />
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant='h5' sx={{marginBottom: '10px', textAlign: {xs: 'center', sm: 'left'}}}>Fundraising Goal: $10,000</Typography>
            <Typography sx={{fontWeight: 400}}>
              Help support our young inventors as they travel to MIT to present
              their invention. The team has been working
              for over 2 years on their project of creating a smart walker
              to assist those with mobility issues.
            </Typography>
            <Typography onClick={() => window.open('https://lemelson.mit.edu/teams/rolling-robots-inventeam')} sx={{'&:hover': {cursor: 'pointer', textDecoration: 'underline'}}}>Read More</Typography>
          </Box>
        </Box>
      </Box>
      </Box>

    </Container>
  )
}

export default Donations

import React from "react";
import { Box } from "@mui/system";
import Container from "@mui/system/Container";
import { Typography } from "@mui/material/";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

const About = () => {
  return (
    <Container>

      <Grid2 item xs={12} style={{ padding: "0px 30px", margin: "auto" }}>
        <Paper sx={{ padding: "20px", margin: "30px" }}>
          <Grid2 item sx={12}>
            <Paper sx={{ background:"linear-gradient(to right, #ffc72c, #ffffff)"}}>
              <Typography variant="h3" sx={{ marginLeft: "10px",color:"#373737" }}>
                  Instructors
              </Typography>
            </Paper>
          </Grid2>
          <Divider/>
          <Grid2 item xs={10} marginLeft="20px" marginTop="20px">
            <Typography variant="h6">
              <span>
                <a /*style={{"text-decoration": "none"}}*/ href="https://rollingrobots.com/sites/default/files/OutreachSiteImgs/GK_Resume.pdf">
                  <p> Dr. George Kirkman</p>
                  {/* <br /> */}
                </a>

                <a href="https://rollingrobots.com/sites/default/files/OutreachSiteImgs/SeanStassi_Resume.pdf">
                  <p>Sean Stassi</p>
                </a>

                <p>Leo</p>               
                {/* <a href="https://rollingrobots.com/sites/default/files/OutreachSiteImgs/Resume_Placeholder.pdf">
                  <p>Leo</p>
                </a> */}
              </span>
            </Typography>                  
          </Grid2>
          {/* {text.image != "" ? <Image src={text.image} /> : null} */}
        </Paper>
        <Divider />
      </Grid2>

      <Grid2 item xs={12} style={{ padding: "0px 30px", margin: "auto" }}>
        <Paper sx={{ padding: "20px", margin: "30px" }}>
          <Grid2 item sx={12}>
            <Paper sx={{ background:"linear-gradient(to right, #ffc72c, #ffffff)"}}>
              <Typography variant="h3" sx={{ marginLeft: "10px",color:"#373737" }}>
                  Legal
              </Typography>
            </Paper>
          </Grid2>
          <Divider/>
          <Grid2 item xs={10} marginLeft="20px" marginTop="20px">
            <Typography variant="h6">
              <span>
                <p> <b>Rolling Robots Outreach, Inc. is a 501 (c) 3 California Corporation.</b></p>
                <a href="https://files.rollingrobots.com/sites/default/files/documents/501%20c%203.pdf">
                  Rolling Robots Outreach 501 (c) 3 determination letter{" "}
                  <br />
                  <br/>
                </a>
                <br />
                <p> <b> Rolling Robots STEM Partners, Inc. is a 501 (c) 3 California Corporation.</b></p>
                {/* <br /> */}
                <a href="https://files.rollingrobots.com/sites/default/files/documents/501c3STEMpartners.pdf">
                  <p>Rolling Robots STEM Partners 501 (c) 3 determination letter</p>
                  <br />
                </a>
              </span>
            </Typography>                  
          </Grid2>
          {/* {text.image != "" ? <Image src={text.image} /> : null} */}
        </Paper>
        <Divider />
      </Grid2>
      
      {/* <Box
        style={{
          height: "fit-content",
          backgroundColor: "white",
          margin: "20px",
          display: "flex",
          justifyContent: "center",
          padding: "30px",
          border:"10px solid #ffc72c"
        }}
      >
        <Typography variant="h6">
          <span>
            <p>Rolling Robots Outreach, Inc. is a 501 (c) 3 California Corporation.</p>
            <br />
            <a href="https://files.rollingrobots.com/sites/default/files/documents/501%20c%203.pdf">
              Rolling Robots Outreach 501 (c) 3 determination letter Rolling{" "}
              <br />
            </a>
            <p>Robots STEM Partners, Inc. is a 501 (c) 3 California Corporation.</p>
            <br />
            <a href="https://files.rollingrobots.com/sites/default/files/documents/501c3STEMpartners.pdf">
              <p>Rolling Robots STEM Partners 501 (c) 3 determination letter</p>
              <br />
            </a>
          </span>
        </Typography>
      </Box> */}
    </Container>
  );
};

export default About;

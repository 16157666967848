import React, { useState, useEffect}  from "react";
import {db, storage} from './Firebase';
import { collection, updateDoc, doc, addDoc, serverTimestamp, setDoc} from "firebase/firestore";
import { ref , uploadBytes , getDownloadURL} from "firebase/storage"
import { v4 } from 'uuid'
import { CabinSharp } from "@mui/icons-material";

const UpdatePost = async (postType, title, tempBody , newDate, images, userID, documentID, otherImages, oldDate ) => { 
    // console.log("UPDATING POST")
    let today = new Date();
    const type = postType;
    const image = images;
    const otherImage = otherImages;
    const header = title;
    const input = tempBody;


    const uid = userID;
    const docID = documentID;

    let imageName;
    let newName;
    const urlList = [];
    const imagesNameArr = [];
    function storeImages(){
        console.log("STORING IMAGES");
        return new Promise((resolve) => {
            const resolveImages = image.map((item) => {
                const thisFile = item.file;
                // const newName =  `images/events/${v4()}.jpg`;
                if (type === "newsPosts"){
                    newName = `images/news/${v4()}.jpg`;
                }
                else {
                    newName = `images/events/${v4()}.jpg`;
                }
                const storageRef = ref(storage, newName);
                imageName = storageRef.name;
                imagesNameArr.push(imageName);
                uploadBytes(storageRef, thisFile).then((snapshot) =>{

                    getDownloadURL(ref(storage, newName)).then((url) =>{
                        const thisURL = url;
                        urlList.push(thisURL);
                    });
                });
            });
            resolve(resolveImages);
        })
    }
        
    async function handleImages(){
        await storeImages();
    }

    //---------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------
    let imgURLs;
    if(type == "newsPosts"){
        try{
            const newsRef = doc(db, "newsPosts", docID);
            console.log(image)
            if(image.length <= 0){            // console.log("no image added - left blank")
                imgURLs = otherImage;
            }else{                          
                handleImages();
                imgURLs = urlList;
            }
            console.log(imgURLs)
            setTimeout(async function(){ 
            await updateDoc(newsRef, {   //const update = ({
                header: header,
                input: input,
                dateCreated: serverTimestamp() ,
                userID: uid,
                imageUrl: imgURLs,
            });
            }, 2000);
            console.log("Document ID:", newsRef.id)
        } catch(err) {
            console.log("error:", err)
        }
    } else {
        //---------------------------------------------------------------------------------------
        let dateOfEvent;
        if( newDate.getTime() === oldDate.getTime()){       
            dateOfEvent = oldDate;
        }else if( newDate.getTime() <= today.getTime() ){   
            dateOfEvent = oldDate;
        }else{                                             
            dateOfEvent = newDate;
        }

        if(image.length <= 0){            // console.log("no image added - left blank")
            imgURLs = otherImage;
        }else{                          
            handleImages();
            imgURLs = urlList;
        }
        //---------------------------------------------------------------------------------------

        setTimeout(async function(){           
            const eventsRef = doc(db, "events", docID);      
            await updateDoc(eventsRef, {    
                header: header,
                dateCreated: serverTimestamp(),
                input: input,
                imageUrl: imgURLs,
                dateSelected: dateOfEvent,
                userID: uid,
            });

        }, 2000);
        console.log("Document ID:", docID)
    }
}

export default UpdatePost;
import React from 'react';
import ReactImageUploading from 'react-images-uploading';
 //Documentation here: https://www.npmjs.com/package/react-images-uploading#usage

 import { Button } from "@mui/material";


export function ImageUpload({images,setImages}) {
 
  const maxNumber = 69;
 console.log(images)
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div className="App">
      <ReactImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button variant='contained'
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </Button>
            &nbsp;
            <Button variant='contained' onClick={onImageRemoveAll}>Remove image</Button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <Button style={{ marginRight: "5px"}} variant='contained' onClick={() => onImageUpdate(index)}>Update</Button>
                  <Button variant='contained' onClick={() => onImageRemove(index)}>Remove</Button>
                </div>
              </div>
            ))}
            {({ imageList, onImageUpload, onImageRemoveAll, errors }) => (
    errors && <div>
      {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
      {errors.acceptType && <span>Your selected file type is not allow</span>}
      {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
      {errors.resolution && <span>Selected file is not match your desired resolution</span>}
    </div>
  )}
  {({ imageList, dragProps, isDragging }) => (
    <div {...dragProps}>
      {isDragging ? "Drop here please" : "Upload space"}
      {imageList.map((image, index) => (
        <img key={index} src={image.data_url} alt=""/>
      ))}
    </div>
  )}
          </div>
        )}
      </ReactImageUploading>
    </div>
  );
}

export default ImageUpload;
import React from "react";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Paper from "@mui/material/Paper";
import CardComponent from "../components/Card";
import Container from "@mui/material/Container";
import EventPosts from "../components/GetEvents";
import { Typography } from "@mui/material";

const size = { size: 400 };

const Events = () => {
  return (
    <div>
      <Container role="main">
        <Typography
          variant="h2"
          align="center"
          margin="10px"
          color="white"
          fontFamily="Bungee"
          role="header"
          aria-label="upcoming events header"
        >
          Upcoming Events
        </Typography>
        <span aria-label="All event posts in order of upcoming-oldest">
          <EventPosts />
        </span>
      </Container>
    </div>
  );
};

export default Events;

import React, { useState, useRef, useEffect } from "react";
import CardComponent from "../components/Card";
import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { RichTextEditor } from "@mantine/rte";
import { TextInput } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { DatePicker} from '@mantine/dates';
import Box from "@mui/system/Box";
import ImageUpload from "../components/ImageUpload";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SavePost from "../components/SavePost";

import {db, auth , currUser, logIn, logOut} from '../components/Firebase'
import { doc, getDoc} from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth"
import { TextField, Grid, /*Box,*/ InputAdornment, IconButton, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {useNavigate} from "react-router-dom";

const FormValues = () => {
  return {
    header: "string",
    input: "string",
  };
};

const textStyle = {
  color: "white",
};

const [FormProvider, useFormContext, useForm] = createFormContext(FormValues());

const NewPost = () => {
  //test is for submiting the full value of the input
  const [test, setTest] = useState([]);
  const [value, setValue] = useState(""); // for the body value
  const [pickedDate, setPickedDate] = useState(new Date());
  const ref = useRef(null) //ref for the date output 
  const [postType, setPostType] = useState("newsPosts"); //for the radio buttons
  const [images, setImages] = React.useState([]);

  //----------------------------------------------------------------
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [editorLabel, setEditorLabel] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLogIn, setShowLogIn] = useState(true);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onFormSubmit = e => {
    e.preventDefault();
  }
  //----------------------------------------------------------------
  const editorRef = useRef(value);

  useEffect(() => {
    editorRef.current.focus();
  }, []);

  const form = useForm({
    initialValues: {
      header: "",
      input: "",
    },
    validate: {
      header: (value) => (value.length > 0 ? null : "required input"),
    },
  });

  //----------------------------------------------------------------
  const AuthStateChange = async () => {
    onAuthStateChanged(auth, (user) => {
      if(user){
        setUser(user);
        setUid(user.uid)
        console.log("user uid:", user.uid);
        setShowLogIn(false);
        setIsShown(false);
      } else {
        console.log("NO USER SIGNED IN")
        setIsShown(false);
        setShowLogIn(true);
      }
    });    
  }

  const CheckUserRole = async () => {
    console.log("CHECKING USER ROLE")
    try{
      const userRef = doc(db, "users", uid);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.data().editor == true ){
        console.log("IS AN EDITOR")
        setIsEditor(true);
        setEditorLabel("SIGNED IN AS AN EDITOR - CAN POST")
        setIsShown(true);
      }else {
        console.log("NOT AN EDITOR")
        setEditorLabel("NOT AN EDITOR - CAN NOT POST");
        setIsShown(true);
      }
    } catch(err){
      console.log("error:", err)
    }
  }

  useEffect(() => {
    AuthStateChange();
    CheckUserRole();
  }, [uid, user]);

  useEffect(() => {
    console.log("label:", editorLabel)
  }, [editorLabel])

  useEffect(() => {
    console.log("isShown:", editorLabel)
  }, [isShown])

  //----------------------------------------------------------------

  //----------------------------------------------------------------
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  //----------------------------------------------------------------

  const handleSubmit = (aaa) => {
    // console.log("postType:", postType);
    let title = aaa.header;
    let body = aaa.input;
    const dateSelected = pickedDate
    let image = images;
    let userID = uid;
    if(isEditor == true){
      SavePost(postType, title, body , dateSelected , image, userID);
    }else{
      alert("NOT AN EDITOR, CAN NOT POST")
    }
    
    setTimeout(() => {
      navigate('/homepage')
    }, 3000);
  };

  //----------------------------------------------------------------
  
  //----------------------------------------------------------------
 
  console.log(test);
  return (
    <>{/* Radio menu to choose if event or news */}
      <Container sx={{ backgroundColor: "#1b5072", marginTop: "30px", marginBottom: "-30px" }}>
        {/*-----------------------------------------------------------*/}
        <div>
            <div> {/*----------*/}
              {showLogIn ?
              <div> 
                <Grid container spacing={2}>
                  <Grid item xs={10} md={5} style={{margin: "0px auto", display: "block"}}>
                    <h3 style={{textAlign: "center", color: "#ffffff"}}>Please log in to create a post</h3>
                      <form onSubmit={onFormSubmit}>
                        <div style={{ display:"flex", flexDirection:"row"}}>
                          <TextField 
                              id="outlined-basic" 
                              label="Email" 
                              variant="outlined"
                              style={{backgroundColor: "white", borderRadius: "5px", marginRight: "10px", width: "200px"}}
                              type="email"
                              value={email}
                              onChange={(element) => setEmail(element.target.value)}
                          />

                          <br></br>
                          <br></br>

                          <TextField 
                              id="outlined-basic" 
                              label="Password" 
                              variant="outlined"
                              style={{backgroundColor: "white", borderRadius: "5px", marginRight: "10px", width: "200px"}}

                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(elem) => setPassword(elem.target.value)}

                              InputProps={{ 
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                          />

                          <br></br>
                          <br></br>

                          <Button type="submit" variant="contained" /*className='submit_button'*/
                          onClick={() => logIn(email, password)}
                          > Login </Button>
                          <br></br>

                          {/* <Button type="submit" variant="contained"
                          onClick={() => logOut(email, password)}
                          > Logout </Button> */}
                          
                        </div>
                      </form>
                  </Grid>
                </Grid>
              </div>
              : <> </>}
            </div>  {/*-----------*/}
              
            {/*-----------------------------------------------------------*/}
            <div>
              {isShown ? 
                <div> 
                  <Typography style={textStyle}>
                    {editorLabel}
                  </Typography>
                  
                  <Button type="submit" variant="contained"
                  onClick={() => logOut(email, password)}
                  > Logout </Button>
                </div>
              :<> </>}
            </div>
            {/*-----------------------------------------------------------*/}

        </div>

        {/*-----------------------------------------------------------*/}
        <br></br>
        {isShown ? 
        <>
        <FormControl>
          <FormLabel
            style={textStyle}
          >
            What type of post are you making?
          </FormLabel>
          <RadioGroup>
            <FormControlLabel
              value={postType}
              control={<Radio/>}
              label="News"
              onClick={() => {
                setPostType("newsPosts");
              }}
              style={textStyle}
              checked={postType === "newsPosts"}
            />
            <FormControlLabel
              value={postType}
              control={<Radio/>}
              label="Events"
              onClick={() => {
                setPostType("events");
              }}
              style={textStyle}
              checked={postType === "events"}
            />
          </RadioGroup>
        </FormControl>
{/*-----------------------------------------------------------------------------------------------*/}
            <div style={{padding:"20px"}}>
              <ImageUpload images={images} setImages={setImages}/>
            </div>
{/*-----------------------------------------------------------------------------------------------*/}
          {postType !== "newsPosts" ? 
          <div style={{marginBottom:"40px"}}>
            <Typography style={textStyle}>
              Event Date?
            </Typography>
            <DatePicker placeholder="Pick Date" allowFreeInput size="" ref={ref}  value={pickedDate} onChange={setPickedDate} inputFormat="MM/DD/YYYY" labelFormat="MM/YYYY"  defaultValue={new Date()}/>
          </div>:null } {/* Documentation on date picker:https://mantine.dev/dates/date-picker/ */}
{/*-----------------------------------------------------------------------------------------------*/}
        </> : <></>}
        <Grid2 container spacing={3} className="Container">
          <FormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            {isShown ?
                <>
              <Grid2 item sx={12} lg={12}>
                <label for="header">
                  <Typography style={textStyle}>Header</Typography>
                </label>
                <TextInput
                  placeholder="header"
                  {...form.getInputProps("header")}
                />
              </Grid2>
              </>
                : <></>}

              {/*------------------------------------------------------------------------------------*/}

              <Grid2 item xs={12} className="Component body input container">
                {isShown ?
                <label for="body">
                  <Typography style={textStyle}>Body</Typography>
                </label>
                : <></>}
                <RichTextEditor
                  ref={editorRef}
                  value={value}
                  onChange={setValue}
                  {...form.getInputProps("input")}
                />
                {isShown ? 
        <></> : <div style={{backgroundColor: "#1b5072", height: "100px", zIndex: "-99999", margin: "-5000px" }}>sadge</div>}
              </Grid2>
              {isShown ? 
                <>
                  <Button variant="contained" type="submit" value="submit" style={{float: "right"}}>Submit</Button>
                </> 
                : <></>}
            </form>
            </FormProvider>
            {/*--------------------------------------------------------------------------------------------*/}
            {isShown ?
            <>
          <Grid2 item xs={12} className="Preview Component container">
            <Typography variant="h4" padding="20px" style={textStyle}>
              <Divider>Preview</Divider>
            </Typography>
            <CardComponent
              eventdate={pickedDate}
              eventcard={postType === "events" ? true:false}
              userinput={form.values.input}
              newsheader={htmlDecode(form.values.header)}
              className="Preview Component"
              img={images.length > 0 ? images[0]['data_url']:null}
              homepage={false}
            />
          </Grid2>
          <Grid2 item xs={12} sx={{ height: "300px", width: "100vw" }}></Grid2>
          </>
            : <></>}
        </Grid2>
      </Container>
    </>
  );
};

export default NewPost;

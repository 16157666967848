import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import CardComponent from "../components/Card";
import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { RichTextEditor } from "@mantine/rte";
import { TextInput } from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { DatePicker} from '@mantine/dates';
import Box from "@mui/system/Box";
import ImageUpload from "../components/ImageUpload";
import { Divider, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import {db, auth} from '../components/Firebase'
import { doc, getDoc} from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth"
import UpdatePost from '../components/UpdatePost'
import {useNavigate} from "react-router-dom";
import { sanitize } from "dompurify";

const FormValues = () => {
    return {
      header: "string",
      input: "string",
    };
};
  
const textStyle = {
color: "white",
};

const textStyleTwo = {
    backgroundColor: "white",
    color: "black",
    padding:"5px",
};
  
const [FormProvider, useFormContext, useForm] = createFormContext(FormValues());

const EditPost = () => {
    const {state} = useLocation();
    const {docID, type} = state;
    const navigate = useNavigate();
    const [postLabel, setPostLabel] = useState(""); // for the body value
    let imageTemp;
    let today = new Date();

    const [value, setValue] = useState(""); // for the body value
    const [pickedDate, setPickedDate] = useState(new Date());
    const ref = useRef(null) //ref for the date output 
    const [postType, setPostType] = useState(type); //for the radio buttons
    const [images, setImages] = React.useState([]);

    //----------------------------------------------------------------
    // const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    // const [isEditor, setIsEditor] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    
    //----------------------------------------------------------------
    const [isLoading, setIsLoading] = useState(true);
    const [isShown, setIsShown] = React.useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [otherImages, setOtherImages] = React.useState([]);

    //both
    const [thisHeader, setHeader] = useState("");
    const [body, setBody] = useState("");
    const [datePosted, setDatePosted] = useState();

    //events only
    const [dateSelected, setDateSelected] = useState();
    const [oldDate, setOldDate] = useState();

    //----------------------------------------------------------------
    const AuthStateChange = async () => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                setUid(user.uid)
            } else {
                console.log("NO USER SIGNED IN")
            }
        });    
    }
      
    useEffect(() => {
        AuthStateChange();
    });
    //----------------------------------------------------------------

    const getFromFirebase = async () => {
        let dateOfEvent;
        let tempDate;

        if(type == "events"){
            setPostLabel("Event")
        }else{
            setPostLabel("News")
        }

        try{
            const docRef = doc(db, type, docID)
            const docSnapshot = await getDoc(docRef);
            const post = docSnapshot.data();
            const dateCreated = post.dateCreated.toDate().toLocaleDateString();
            
            if(post.dateSelected !== undefined){
                dateOfEvent = post.dateSelected.toDate().toLocaleDateString();
                tempDate = post.dateSelected.toDate();
                setOldDate(tempDate);
            } else {
                dateOfEvent = "No Date Selected";
            }

            if (type !== "newsPost"){
                //events
                setHeader(post.header);
                setBody(post.input);
                setDatePosted(dateCreated);
                setDateSelected(dateOfEvent);

                if(post.imageUrl !== undefined){
                    if(typeof post.imageUrl === 'string'){  // console.log("image is a string")
                        imageTemp = post.imageUrl;
                    }else{ 
                        const thisTemp = post.imageUrl;
                        if(thisTemp.length <= 0){      // console.log("array is empty")
                            imageTemp = null;
                        }else{                              // console.log("array is not empty")
                            imageTemp = post.imageUrl[0];
                        }
                    }
                } else {
                    imageTemp = null;
                }
                setOtherImages(imageTemp )
            }else {
                //news
                setHeader(post.header);
                setBody(post.input);
                setDatePosted(dateCreated);

                if(post.imageUrl !== undefined){
                    if(typeof post.imageUrl === 'string'){  // console.log("image is a string")
                        imageTemp = post.imageUrl;
                    }else{ 
                        const thisTemp = post.imageUrl;
                        if(thisTemp.length <= 0){      // console.log("array is empty")
                            imageTemp = null;
                        }else{                              // console.log("array is not empty")
                            imageTemp = post.imageUrl[0];
                        }
                    }
                } else {
                    imageTemp = null;
                }
                setOtherImages(imageTemp)
            }            
        }catch(err){
            console.log("error:", err)
        }
        setIsFinished(true);
    }
    
    // useEffect(() => {
    //     console.log("useEffect - body changed:", body);
    // },[body])

    // useEffect(() => {
    //     console.log("useEffect - header changed:", thisHeader);
    // },[thisHeader])

    // useEffect(() => {
    //     console.log("useEffect - otherImages changed:", otherImages);
    // },[otherImages])

    useEffect(() => {
        if(thisHeader !== null ){
            if (body !== null){
                setIsLoading(false);
                setIsShown(true);
            }else{
                console.log("body:null")
            }
        } else {
            console.log("header null")
        }
    },[isFinished])


    useEffect(() => {
        getFromFirebase();
    },[]);
    
    //----------------------------------------------------------------

    const form = useForm({
        initialValues: {
            header: "",
            input: "",
        },

        validate: {
        header: (value) => (value.length > 0 ? null : "required input"),
        },

    }, []);

  //----------------------------------------------------------------
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  //----------------------------------------------------------------

    const handleSubmit = (aaa) => {
        let title = aaa.header;
        let tempBody = aaa.input;
        let userID = uid;
        let documentID = docID;

        let image = images;
        let newDate = pickedDate

        UpdatePost(postType, title, tempBody , newDate , image, userID, documentID, otherImages, oldDate);

        if(postType == "newsPosts"){
            setTimeout(() => {
                navigate('/news')
            }, 3000)
        }else{
            setTimeout(() => {
                navigate('/events')
            }, 3000)
        }
    };

  //----------------------------------------------------------------
    const handleChange = (event, setHeader) => {
        setHeader(event.target.value);
        console.log(event.target.value);
    }
  //----------------------------------------------------------------
 
    return (
    <>{/* Radio menu to choose if event or news */}
        <Container sx={{ backgroundColor: "#1b5072", marginTop: "30px", marginBottom: "-30px" }}>
            <div>
                <div>
                { isLoading == true ?
                    <Typography style={textStyle}>
                        LOADING INFO
                    </Typography>
                : <></>}
                </div>
            </div>
            
            {/* <div> */}
                {isShown ?   
                <div>
                    <Grid2 item xs={12} className="Preview Component container">
                        <Typography variant="h4" padding="20px" style={textStyle}>
                        <Divider>Preview Of Current Post</Divider>
                        </Typography>

                    {postLabel !== "News"?      
                        <CardComponent
                            eventdate={dateSelected}
                            timestamp={datePosted}
                            eventcard={type === "events" ? true:false}
                            userinput={body}
                            newsheader={htmlDecode(thisHeader)}
                            className="Current Component"
                            img={otherImages ? otherImages:null}
                            homepage={false}
                        />
                    : <> 
                    <Grid2 item xs={12}>
                        <Box style={{ padding: "10px", margin: "10" }}>
                            <CardComponent
                                newsheader={thisHeader}
                                userinput={body}
                                timestamp={datePosted}
                                color="#ffe6a2"
                                homepage={false}
                                img={otherImages ? otherImages:null}
                            />
                        </Box>
                    </Grid2>
                     
                    </>   }
                    </Grid2>

                    <Typography variant="h4" padding="20px" style={textStyle}>
                        <Divider>Edit {postLabel} Post</Divider>
                    </Typography>
                    {/*-----------------------------------------------------------------------------------------------*/}
            
                    
                    {/*-----------------------------------------------------------------------------------------------*/}
                        <div style={{paddingBottom:"40px"}}>
                            <Typography style={textStyle}>
                                Replace Image
                            </Typography>

                            <ImageUpload images={images} setImages={setImages}/>
                        </div>
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {postType !== "newsPosts" ? 
                        <div style={{marginBottom:"40px"}}>
                            <Typography style={textStyle}>
                                Event Date
                            </Typography>
                            <DatePicker placeholder="Pick Date" allowFreeInput size="" ref={ref}  value={pickedDate} onChange={setPickedDate} inputFormat="MM/DD/YYYY" labelFormat="MM/YYYY"  defaultValue={new Date()}/>
                        </div> :null } {/* Documentation on date picker:https://mantine.dev/dates/date-picker/ */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    <Grid2 container spacing={3} className="Container">
                        <FormProvider form={form}>
                            <form onSubmit={form.onSubmit((e) => handleSubmit(e))}>
                                <Grid2 item sx={12} lg={12}>
                                    <label for="header">
                                        <Typography variant="h6" paddingBottom="2px" style={textStyle}>
                                            Header
                                        </Typography>
                                    </label>
                                    <TextInput
                                        placeholder={thisHeader}
                                        value={thisHeader}
                                        {...form.getInputProps("header")}
                                    />
                                    <Typography style={textStyle}> Current Header: {thisHeader} </Typography>

                                </Grid2>
                                
                                {/*------------------------------------------------------------------------------------*/}
                                
                                {/*------------------------------------------------------------------------------------*/}
                                <Grid2 item xs={12} className="Component body input container">
                                    <label for="header">
                                        <Typography variant="h6" paddingBottom="2px" style={textStyle}>
                                            Body
                                        </Typography>
                                    </label>
                                    <RichTextEditor
                                        value={value}
                                        onChange={setValue}
                                        {...form.getInputProps("input")}
                                    />
                                    <div style={{paddingTop:"10px" , paddingBottom:"10px", border:"5px"}}>
                                        <Typography style={textStyle}> Current Body: </Typography>
                                        <RichTextEditor
                                            value={body}
                                            readOnly
                                        />
                                        {/* <Typography style={textStyleTwo} dangerouslySetInnerHTML={{ __html: sanitize(body) }}/> */}

                                    </div> 
                                </Grid2>
                                {/* <input type="submit" value="submit" /> */}
                                <Button variant="contained" type="submit" value="submit" style={{float: "right"}}>Submit</Button>
                            </form>
                        </FormProvider>
                        {/*--------------------------------------------------------------------------------------------*/}
                        <Grid2 item xs={12} className="Preview Component container">
                            <Typography variant="h4" padding="20px" style={textStyle}>
                                <Divider>Preview Edited Post</Divider>
                            </Typography>

                            {postLabel !== "News"?  
                                <CardComponent
                                eventdate={pickedDate}
                                eventcard={type === "events" ? true:false}
                                userinput={form.values.input}
                                newsheader={htmlDecode(form.values.header)}
                                className="Preview Component"
                                img={images.length > 0 ? images[0]['data_url']:null}
                                homepage={false}
                                />
                            : <> 
                                <Grid2 item xs={12}>
                                    <Box style={{ padding: "10px", margin: "10" }}>
                                        <CardComponent
                                            newsheader={htmlDecode(form.values.header)}
                                            userinput={form.values.input}
                                            color="#ffe6a2"
                                            eventdate={pickedDate}
                                            homepage={false}
                                            img={images.length > 0 ? images[0]['data_url']:null}
                                        />
                                    </Box>
                                </Grid2>
                            </>}
                        </Grid2>
                        <Grid2 item xs={12} sx={{ height: "300px", width: "100vw" }}></Grid2>
                    </Grid2>
                </div> : <> </> }
            {/* </div> */}
       
        </Container>
    </>
    );
}

export default EditPost;


///////////////////////////////////





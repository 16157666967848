import React from "react";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "mui-image";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import GetEvents from "../components/GetEvents";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  MoveOut,
} from "react-scroll-motion";
import GetNews from "../components/GetNews";
import Divider from "@mui/material/Divider";
import CardComponent from "../components/Card";
import Container from "@mui/material/Container";

import backgroundImage from "../Images/gear-1s-1084pxYellow.png";
import "./Homepage.css";


const homepageText = (
  <p>
    Welcome to the Rolling Robots Outreach and STEM Partners Program, our web
    portal for all of our charitable works and community-driven learning
    initiatives. For years now Rolling Robots has put a huge emphasis on making
    sure that the students in the communities around us have access to a STEM
    (Science, Technology, Engineering, and Math) education regardless of whether
    or not those programs existed in their area or if they could afford them.
    After years of working towards this goal and listening to feedback from
    parents and teachers about how we could extend our reach even further, we
    decided it was finally time to step our efforts up to the next level. It is
    for this reason that we have formed Rolling Robots Outreach Inc, and Rolling
    Robots STEM Partners a 501c3 non-profit formed together with a single
    mission: to promote STEM education and extend access to cutting-edge
    learning resources to students of all backgrounds and locales.
    <br />
    <br />
    Our basic strategy is as follows:
    <br />
    <ul>
      <li>
        Launch a team of students who will serve as STEM Ambassadors in the
        community to promote STEM education among their peers.
      </li>
      <li>
        Develop "Inventor Studio" style engineering lab facilities. These will
        be unique places to train and support students as engineers in the
        Rolling Robots way. This will be done in dedicated spaces as well as in
        partnerships with existing community organizations.
      </li>
      <li>
        Develop partnerships to create Community Based STEM Education Centers.
      </li>
    </ul>
  </p>
);


const textStyle = {
  color: "white",
  fontWeight: "600",
};
const Homepage = ({ header, text }) => {

  return (
    <Container disableGutters maxWidth="100%" role="main">
      <Grid2
        container
        spacing={3}
        id="splash photo and about us content"
      >
        <Grid2 item xs={12}>
          <Box sx={{ position: "relative", textAlign: "center" }}>
            <Typography
              variant="h1"
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                color: "white",
              }}
              id="header"
              role="banner"
              title="rolling robots outreach"
            >
              Welcome to Rolling Robots OutReach
            </Typography>
            <ScrollContainer>
              <ScrollPage>
                <Animator animation={batch(Fade(), MoveOut(0, -100))}>
                  <Image
                    margin=""
                    height="100vh"
                    width="100vw"
                    shift="top"
                    fit="cover"
                    easing="cubic-bezier(0.7,0,0.6,1)"
                    bgColor="inherit"
                    duration="3000"
                    src={require("../Images/IMG_1423.JPG")}
                    alt="rolling robots team in front of a spaceX rocket"
                    sx={{
                      zIndex: "-1",
                      position: "relative",
                    }}
                  />
                </Animator>
              </ScrollPage>
            </ScrollContainer>
          </Box>
          <Grid2
            item
            xs={12}
            className="about container"
            roll="region"
            aria-labelledby="Rolling Robots OutReach information"
          >
            <Box
              className="about text and icon"
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "space-around",
                height: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    display: "flex",
                    zIndex: 2,
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h3" role="heading">
                    Rolling Robots OutReach
                  </Typography>
                </Paper>

                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                  }}
                >
                  <Typography>
                    <p>{homepageText}</p>
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            style={textStyle}
            role="region"
            aria-labelledby="upcoming or recent events"
          >
            Events
          </Typography>
        </Grid2>

        <Grid2
          item
          xs={12}
          className="dark blue background container"
          sx={{
            height: "100%",
            padding: "20px",
            backgroundColor: "primary.dark",
            position: "relative",
          }}
        >
          <Grid2
            item
            xs={12}
            sx={{
              height: "100%",
              borderRadius: "30px",
              position: "relative",
            }}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "repeat",
              backgroundColor: "yellow",
            }}
            className="yellow container"
          >
            <GetEvents homepage={true} />
          </Grid2>
        </Grid2>
      </Grid2>
      {/* =========================================  */}
      <Grid2
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        className="news container"
      >
        <Typography
          variant="h3"
          style={textStyle}
          marginTop="30px"
          role="heading"
        >
          News
        </Typography>
      </Grid2>
      <Grid2
        role="region"
        aria-labelledby="recent news posts"
        xs={12}
        sx={{
          padding: "30px",
          margin: "30px",
        }}
      >
        <GetNews homepage={true} />
      </Grid2>
    </Container>
  );
};

export default Homepage;

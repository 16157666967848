import React, { useState, useEffect } from "react";
import CardComponent from "../components/Card";
import { db, auth } from "./Firebase";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { collection, where, query, orderBy, getDocs } from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth"

const EventPosts = (props) => {
  
  console.log("EventsPosts.js");
  let type = "events";
  const [allEvents, setAllEvents] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  let today = new Date();

  //----------------------------------------------------------------
  const AuthStateChange = async () => {
    onAuthStateChanged(auth, (user) => {
      if(user){        
        setIsEditable(true)
      } else {
        console.log("NO USER SIGNED IN")
      }
    });    
  }
  
  useEffect(() => {
    AuthStateChange();
  })
  //----------------------------------------------------------------

  const getAllEventPosts = async () => {
    try {
      const eventsArr = [];
      let dateOfEvent;
      let imageTemp;

      const eventsRef = collection(db, "events");
      const q = query(eventsRef, where("dateSelected", ">=", today), orderBy("dateSelected", "desc"));
      const eventsSnapshot = await getDocs(q);
      eventsSnapshot.forEach((doc) => {
        const dateCreated = doc
          .data()
          .dateCreated.toDate()
          .toLocaleDateString();

        if(doc.data().dateSelected !== undefined){
          dateOfEvent = doc.data().dateSelected.toDate().toLocaleDateString();
        } else {
          dateOfEvent = "No Date Selected";
        }

        if(doc.data().imageUrl !== undefined){
          if(typeof doc.data().imageUrl === 'string'){
            imageTemp = doc.data().imageUrl;
          }else if(doc.data().imageUrl == null){
            imageTemp = null;
          }else{
            imageTemp = doc.data().imageUrl[0];
          }
        } else {
          imageTemp = null;
        }

        const event = {
          id: doc.id,
          header: doc.data().header,
          date: dateCreated,
          image: imageTemp,
          eventDate: dateOfEvent,
          body: doc.data().input,
        };
        eventsArr.push(event);
      });
      setAllEvents(eventsArr);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllEventPosts();
  }, []);

  //----------------------------------------------------------------
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  //----------------------------------------------------------------
  const sliced = allEvents.slice(0, 3);
  //----------------------------------------------------------------

  const RenderEvents = () => {
    return (
      <div>
        {!props.homepage ? (
          <Grid2 container spacing={3}>
            {allEvents.map((item, index) => {
              return (
                <Grid2 item xs={12}>
                  <Box key={index}>
                    <CardComponent
                      userinput={item.body}
                      newsheader={htmlDecode(item.header)}
                      className="Preview Component"
                      id={item.id}
                      timestamp={item.date}
                      eventdate={item.eventDate}
                      eventcard={true}
                      homepage={false}
                      clickable={false}
                      img={item.image}
                      editable={isEditable}
                      docID={item.id}
                      type={type}
                    />
                  </Box>
                </Grid2>
              );
            })}
          </Grid2>
        ) : (
          <Grid2
            item
            xs={12}
          
            style={{
           display:"flex",
           flexFlow:"row wrap",
           gap:"30px",
           justifyContent:"center",
    

            }}
          >
            {sliced.map((item) => (
              <CardComponent
                userinput={item.body}
                newsheader={htmlDecode(item.header)}
                id={item.id}
                timestamp={item.date}
                eventdate={item.eventDate}
                eventcard={true}
                homepage={true}
                clickable={true}
                cardwidth="600px"
                img={item.image}
              />
            ))}
          </Grid2>
        )}
      </div>
    );
  };
  {
    /* if the page this compoenent is on is the home page than it will only display 3 + it will be clickable*/
  }

  return (
    <div>
      <RenderEvents />
    </div>
  );
};

export default EventPosts;

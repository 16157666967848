import React from "react";
import Card from "../components/Card";
import image from "../Images/Document.jfif";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Container from "@mui/system/Container";
import { Stack } from "@mui/system";
import Box from "@mui/system/Box";
const Locations = () => {
  const locations = [
    {
      name: " Glendale",
      address: `Artsakh Creative District
      131 N. Artsakh Ave,<br/>
      Glendale, California, 91206`,
      phone: "(888) ROBOT-08",
      link:"https://goo.gl/maps/sbDt4A36CPPRQfpr5",
      img:"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=vBgclQ8FYFG8MpK9lZwc0w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=304.50458&pitch=0&thumbfov=100"
    },
    {
      name: "Palos Verdes ",
      address: `704 Bart Earle Way,</br> #201
      Rolling Hills Estates,</br> California, 90274`,
      phone: "(424) 206-9466",
      link:"https://goo.gl/maps/GXh6ZumFtq3QqWRX9",
      img:"https://www.rpvca.gov/ImageRepository/Document?documentID=14755"
    },
    {
      name: "West LA",
      address: `2951 S Sepulveda Blvd.</br>
      Los Angeles, CA 90064`,
      phone: "(310) 474-0198",
      link:"https://goo.gl/maps/8sDcGufJgf4HEhf5A",
      img:"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=CbsrBFncJPf2ZzEiZkwRUQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=183.45773&pitch=0&thumbfov=100"
    },
    {
      name: "Hermosa Beach",
      address: `<strong>Hermosa Beach Rotary Club</strong></br>
      2521 Valley Dr,</br>
      Hermosa Beach, CA 90254`,
      phone: "(888) ROBOT-08",
      link:"https://goo.gl/maps/um6ECHRH1wyK25A98",
      img:"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=y5PeEIEibxt2xcpe5tV_uw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=256.72946&pitch=0&thumbfov=100"
    },
    {
      name: "Irvine",
      address: `830 Roosevelt,</br>
      Irvine, CA 92620`,
      phone: "(888) ROBOT-08",
      link:"https://goo.gl/maps/j2RkdTeMCS9mjvri7",
      img:"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=vk9VyQQ-_NZexMdmWOv1cQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=236.39233&pitch=0&thumbfov=100"
    },
    {
      name: "La Crescenta",
      address: `<strong>Robins Nest Preschool</strong></br>
      2920 Honolulu Ave,</br>
      La Crescenta-Montrose, CA 91214`,
      phone: "(888) ROBOT-08",
      link:"https://goo.gl/maps/kVjLCXo96gt7yzBC7",
      img:"https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=pw5em-RPG0ZT_laLqy5YKQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=213.01508&pitch=0&thumbfov=100"
    },
    {
      name: "Torrance",
      address: `El Camino Kids College</br>
      16007 Crenshaw Blvd,</br>
      Torrance, CA 90506`,
      phone: "(888) ROBOT-08",
      link:"https://goo.gl/maps/P4Phukgn7PWpNmar6",
      img:"https://lh5.googleusercontent.com/p/AF1QipNJNCmLmPHUsFL6FaR4J2P2F303bhx1iQt2NJDk=w408-h272-k-no"
    },
    {
      name: "Long Beach",
      address: `<strong>Los Altos Family YMCA</strong></br>
      1720 N Bellflower Blvd,</br>
      Long Beach, CA 90815`,
      phone: "(562) 596-3394",
      link:"https://goo.gl/maps/KrY11t21rsiyAjti8",
      img:"https://lh5.googleusercontent.com/p/AF1QipPXyPzEe9qK6GpRLuKlndOdgLEllGZUfow7BM2h=w408-h291-k-no"
    },
  ];

  const styles = {
    one: {
      padding: "30px",
    },
    two: {
      padding: "20px",
      width:"600px",
      position: "relative",
      
    },
  };
  return (
    <Container size="lg" role="main" aria-label="all of Rolling Robots locations">
      <Grid2 container spacing={2} sx={{display:"flex",justifyContent:"center",marginTop:"30px",marginBottom:"30px"}}>
        {locations.map((location) => {
          return (
            <Grid2 item sx={6} lg={3} style={styles.two}>
              <Card
                newsheader={location.name}
                userinput={location.address}
                locationtype={true}
                addresslink={location.link}
                phonenumber={location.phone}
                img={location.img}
                eventcard={true}
                clickable={false}
                
                
              />
            </Grid2>
          );
        })}
      </Grid2>
    </Container>
  );
};

export default Locations;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import App from "./App";
import Donations from "./Pages/Donations";
import Navbar from "./components/Navbar";
import Events from "./Pages/Events";
import Homepage from "./Pages/Homepage";
import Footer from "./components/Footer";
import Activities from "./Pages/Activities";
import Locations from "./Pages/Locations";
import NewPost from "./Pages/NewPost";
import About from "./Pages/About";
import GetNews from "./components/GetNews";
import { MantineProvider } from "@mantine/core";
import EditPost from "./components/EditPost";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const defaultTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#7aaed3",
      light: "#ace0ff",
      dark: "#497fa2",
      contrastText: "white",
    },
    secondary: {
      main: "#ffc72c",
      light: "#FFE292",
      dark: "#c79700",
      contrastText: "white",
    },
    error: {
      main: "#c8102e",
    },
    divider: "#74a9aa",
    background: {
      default: "#184867",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 800,
    },
    h2: {
      fontFamily: "Montserrat",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    h4: {
      fontFamily: "Montserrat",
    },
    h5: {
      fontFamily: "Montserrat",
    },
    h6: {
      fontFamily: "Montserrat",
    },
    subtitle1: {
      fontFamily: "Quicksand",
    },
    subtitle2: {
      fontFamily: "Quicksand",
    },
    body1: {
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    body2: {
      fontFamily: "Quicksand",
    },
    caption: {
      fontFamily: "Quicksand",
    },
    button: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    overline: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
  },
});

root.render(
  <MantineProvider>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Router>
        <Navbar />
        {/*Header*/}
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/events" element={<Events />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/newpost" element={<NewPost />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<GetNews />} />
          <Route path="/editpost" element={<EditPost />} />
          <Route exact path="/"></Route>
        </Routes>
        {/*Footer */}
        <Footer />
      </Router>
    </ThemeProvider>
  </MantineProvider>
);

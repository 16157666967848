import React from "react";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActionArea } from "@mui/material";
import { sanitize } from "dompurify";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import robotplaceholder from "../Images/robie_head (1).svg";
import ShowMoreText from "react-show-more-text";
import { useNavigate } from "react-router-dom";

/* Props guide for this card component.
newsheader = the header for the card component. It is optional. 
userinput = the text body within the card component. it is not optional 
userimage = generates an image that goes the entire width of the card. It is optional.
cardwidth- card width 
cardheight- card height 
id- unique id for the card

CARD IDENTIFIERS 
eventcard- marks this as an event card
locationtype- marks this as a location card

LOCATION ONLY
address link- link to the google directions
phone number- phone number, this is clickable to make a direct call on mobile
link- links the card to an outside location 

style- uhh unsure 
color- will change the color of the header background 
clickable- if true is clickable and will redirect you to the news/location/event page
homepage- if true this card is located on the homepade
EVENTCARD ONLY- 
(eventcard)
img 
eventdate
*/

const CardComponent = ({
  newsheader,
  userinput,
  userimage,
  cardwidth,
  cardheight,
  link,
  id,
  img,
  eventcard,
  locationtype,
  eventdate,
  addresslink,
  phonenumber,
  style,
  color,
  clickable,
  timestamp,
  homepage,
  editable,
  docID,
  type
}) => {
  // const timestamp = new Date();
  const textStyle = {
    textDecoration: "none",
    color: "black",
    padding: "10px",
  };

  //---------------------------------------------------------------
  const styles = (theme) => ({
    root: {
      height: "150px",
      [theme.breakpoints.up("md")]: {
        height: "50px",
      },
    },
  });

  //------------------------------------------------
  const navigate = useNavigate();
  //----------------------------------------------------------------

  return (
    <Card
      sx={{
        width: `${cardwidth}`,
        padding: "10px",
        height: "100%",
        display: "flex",
      }}
      className="CardElement"
    >
      {/* If the Card is an eventCard it will show a default image if no image is uploaded */}
        <styles style={{ display: "flex" }}>
          <CardMedia
            sx={{ display: "flex" }}
            component="img"
            height={styles}
            image={img ? img : robotplaceholder}
            align="center"
            style={{ height: "200px", width: "auto", paddingTop: "20px" }}
          />
        </styles>

      {homepage === true ? (
        <CardActionArea
        disabled={clickable ? false : true}
        aria-label={homepage ? "main content area" : "article content"}
        id="content_area"
      >
        {/* This checkes if the card should be clickable or not it should be clickable by default   */}
        <Link
          disabled={clickable ? false : true}
          aria-labelledby="content_area"
          to={eventcard ? "/events" : "/news"}
          style={textStyle}
        >
          {/*checks if the link should redirect to news or events depending on label  */}
          <CardContent>
            { eventcard ? (<Paper
              style={{
                backgroundColor: `${color}`,
                width: "100%",
                padding: "4px",
                width: "290px",
              }}
              elevation={0}
            >
              <Typography
                noWrap={true}
                variant="h4"
                color="pallet.type.primary"
                role="banner"
                id="headercontent"
              >
                {newsheader}
                {/* Header */}
              </Typography>
            </Paper>) : (
              <Paper
              style={{
                backgroundColor: `${color}`,
                width: "100%",
                padding: "4px",
              }}
              elevation={0}
            >
              <Typography
                noWrap={true}
                variant="h4"
                color="pallet.type.primary"
                role="banner"
                id="headercontent"
              >
                {newsheader}
                {/* Header */}
              </Typography>
            </Paper>
            )}

            {locationtype ? null : (
              <Typography aria-label="Timestamp for when the post was created">{`${timestamp}`}</Typography>
            )}
            {/* removes the timestamp for the location cards*/}
            {eventcard ? (
              <Typography
                fontWeight={800}
                aria-label="date for when the event is occuring"
              >
                Event Date: {`${eventdate}`}
              </Typography>
            ) : null}
            <>
            { eventcard ? (<ShowMoreText
              more={<Link to={eventcard ? "/events" : "/news"}><p style={{ textAlign: "right" }}>Click here for more info</p></Link>}
              lines={2}
            >
              <Typography
                aria-label="news,event,or location content"
                dangerouslySetInnerHTML={{ __html: sanitize(userinput) }}
                variant={locationtype ? "h5" : null}
              />
            </ShowMoreText>) : (
              <ShowMoreText
              more={<Link to={eventcard ? "/events" : "/news"}><p style={{ textAlign: "right" }}>Click here for more info</p></Link>}
              lines={4}
            >
              <Typography
                aria-label="news,event,or location content"
                dangerouslySetInnerHTML={{ __html: sanitize(userinput) }}
                variant={locationtype ? "h5" : null}
              />
            </ShowMoreText>
            )}
            </>
            {/*sanitizes and inputs user input from the form in a converted rich text format*/}
            <Typography style={{ textStyle }} aria-label="telephone number">
              {locationtype ? (
                <a href="tel:" {...phonenumber}>
                  {sanitize(phonenumber)}
                </a>
              ) : null}{" "}
              {/*enables phone number for locationcards */}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>) : (<></>)}

      {homepage === false ? (
        <CardActionArea
          aria-label={homepage ? "main content area" : "article content"}
          id="content_area"
          style={textStyle}
        >
          <Paper
            style={{
              backgroundColor: `${color}`,
              width: "100%",
              padding: "4px",
            }}
            elevation={0}
          >
            <Typography
              noWrap={true}
              variant="h4"
              color="pallet.type.primary"
              role="banner"
              id="headercontent"
            >
              {newsheader}
              {/* Header */}
            </Typography>
          </Paper>
          <Typography aria-label="Timestamp for when the post was created">{`${timestamp}`}</Typography>
          {eventcard ? (
                <Typography
                  fontWeight={800}
                  aria-label="date for when the event is occuring"
                >
                  Event Date: {`${eventdate}`}
                </Typography>
              ) : null}
          <Typography
            aria-label="news,event,or location content"
            dangerouslySetInnerHTML={{ __html: sanitize(userinput).replace(/href/g, "target='_blank' href") }}
            variant={locationtype ? "h5" : null}
          />
          <Typography style={{ textStyle }} aria-label="telephone number">
                {locationtype ? (
                  <a href="tel:" {...phonenumber}>
                    {sanitize(phonenumber)}
                  </a>
                ) : null}{" "}
                {/*enables phone number for locationcards */}
              </Typography>
        </CardActionArea>) : (<></>)}
      
      <div style={{ marginTop: "37px" }}>
        <CardActionArea>
          {editable ? (
            <Link
              disabled={editable ? false : true}
              aria-labelledby="content_area"
              to="/editpost"
              state={{docID: docID, type: type }}
              style={textStyle}
            >
              <Button type="button" variant="contained"
                > EDIT
              </Button>
            </Link>
          ) : null}
        </CardActionArea>
      </div>
    </Card>
  );
};

export default CardComponent;
